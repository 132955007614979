import { useEffect, useState } from "react";

import { Menu, Seo } from "~components";

import { Layout } from "~components/common/Layout";
import * as useEventsService from "~features/events/hooks/useEventsService";
import { EventQuery } from "~graphql/sdk";
import EventsCalendarOverview from "~form/calendar/calendarOverview";
import { useEventAccessControl } from "~hooks/useEventAccessControl";
import { useRouter } from "next/router";

const CalendarHomePage = () => {
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<EventQuery | null>(null);
  const router = useRouter();

  // const { events } = useEventsService.useEventsService({
  //   includeFeaturedEvents: true,
  // });

  // const firstEvent = events?.sort(
  //   (a, b) =>
  //     new Date(a.dates[0].startDate).getTime() -
  //     new Date(b.dates[0].startDate).getTime()
  // )[0];

  const { event, release, isAdmin, isLoading } = useEventAccessControl(
    selectedEventId // ?? firstEvent?.id
  );

  useEffect(() => {
    if (event?.id) {
      const offset = 16; //88; //activeBannerHeight + (TABS_HEIGHT - 2);

      const top =
        window.scrollY +
        document.getElementById("event-banner")?.getBoundingClientRect().top -
        offset;

      window.scrollTo({
        behavior: "smooth",
        top,
      });
    }
  }, [event?.id]);

  useEffect(() => {
    if (router?.query?.zoneId) {
      const topNav = document.getElementById("top-nav");
      topNav.style.visibility = "hidden";
      console.log("topNav hide");
    } else {
      const topNav = document.getElementById("top-nav");
      topNav.style.visibility = "visible";
      console.log("topNav show");
    }
  }, [router?.query]);

  return (
    <>
      <Seo title="Calendar" description="Event overview page for" />

      <Layout
        layoutType="main"
        css={`
          padding-top: 0 !important;
        `}
      >
        <Menu sticky={false} />
        <EventsCalendarOverview
          release={release}
          isLoading={isLoading}
          selectedEvent={event}
          isAdmin={isAdmin}
          selectedEventId={selectedEventId}
        />
      </Layout>
    </>
  );
};

export default CalendarHomePage;
