/* eslint-disable react/prop-types */
import { useRouter } from "next/router";
import { NonSeated as NonSeatedComponent } from "~components/reservation";

import { EventKind, ReleaseType } from "~graphql/sdk";
import {
  EventAndReleaseQuery,
  EventAndReleaseZoneTicket,
  EventPromotion,
  IEventReservationPage,
} from "~features/reservation/reservation-types";
import {
  INormalisedData,
  IPromotion,
  IPromotionTicketType,
  IReleaseTicket,
  IReleaseZone,
  TicketTypeType,
} from "../nonseated-reservation.types";
import {
  useCreateGAOrder,
  usePOSIdLocalStorage,
  useQueryFilteredZones,
} from "../nonseated.hooks";
import { NonSeatedReservationPage } from "~features/reservation/non-seated/NonSeatedReservationPage";
import { useOrganization } from "~hooks";
import { formatEventDateRange } from "~lib/helpers/formatDate";

export interface INonSeatedEventReservation extends IEventReservationPage {}

export default function NonSeatedEventReservation(
  props: INonSeatedEventReservation
) {
  const VM = useViewModel(props);
  const { hasFeature } = useOrganization();

  const Component = !hasFeature("LegacyGATicketSelection")
    ? NonSeatedReservationPage
    : NonSeatedComponent;

  console.log({ VM });
  return (
    <Component
      isMembership={VM.isMembership}
      isAdmin={VM.isAdmin}
      zones={VM.zones}
      addons={VM.addons}
      data={VM.data}
      createOrder={VM.handleOrderCreation}
      releaseId={VM.release?.id}
      releaseNotes={VM.release?.releaseNotes}
      allowPublicComp={VM.release?.allowPublicComp}
      isResaleRelease={VM.release?.isResaleRelease}
      releaseType={VM.release?.type}
      requiresAuth={VM.release?.requiresAuth}
    />
  );
}

export function useViewModel(props: INonSeatedEventReservation) {
  const query = useRouter()?.query;

  usePOSIdLocalStorage();

  const data = useCreateSharedDataModel({
    event: props.event,
    release: props.release,
  });

  const handleOrderCreation = useCreateGAOrder({
    releaseId: data.release?.id,
  });

  const zones = useQueryFilteredZones(data.zones, query?.zone);

  const addonTypes = data.addons?.filter(
    (addon) => addon.isActive || props.release?.type !== ReleaseType.Listed
  );

  return {
    zones,
    addons: addonTypes,
    data: data.data,
    handleOrderCreation,
    release: props.release,
    isMembership: false,
    isAdmin: props.isAdmin,
  };
}

export function useCreateSharedDataModel({
  event,
  release,
}: {
  event: EventAndReleaseQuery["event"];
  release: EventAndReleaseQuery["release"];
}) {
  const { organization, hasFeature } = useOrganization();

  function normalisePromotionTicket(
    ticket: EventPromotion["buyTicketType"] | EventPromotion["getTicketType"]
  ): IPromotionTicketType {
    return {
      id: ticket.id,
      name: ticket.name,
      description: ticket.description,
      restrictions: ticket.restrictions,
      kind: ticket.kind,
    };
  }

  function normalisePromotion(promotion: EventPromotion): IPromotion {
    return {
      ...promotion,
      buyTicketType: normalisePromotionTicket(promotion.buyTicketType),
      getTicketType: normalisePromotionTicket(promotion.getTicketType),
    };
  }

  const normalisedData: INormalisedData = (() => {
    const displayDate = formatEventDateRange(event.startDate, event.endDate, {
      format: "basicLong",
      showStartTime: !hasFeature("hideEventTime"),
      timeZone: event.venue?.timezone,
      locale: event.venue?.locale,
    });

    if (event) {
      return {
        id: event.id,
        type: "event",
        name: event.title,
        gateways: event.gateways,
        description: event.description,
        location: event.venue?.name
          ? `${event.venue?.name}, ${event.venue?.address?.city}`
          : "",
        displayDate,
        importantNotice: event.importantNotice,
        multiBuyPromotions: event.multiBuyPromotions.map(normalisePromotion),
        backgroundImageFullPage: event.backgroundImageFullPage,
        backgroundImage: event.backgroundImage,
        banner: event.logo,
        digitalPassCodesStatus: event.digitalPassCodesStatus,
        ticketResaleWaitlistEnabled: event.ticketResaleWaitlistEnabled,
        bookingFee: event.bookingFee,
        bookingFeeType: event.bookingFeeType,
        isDigital: event.eventKind === EventKind.Digital,
        isR18: event.isR18,
        taxRate: event.taxRate,
        customTaxRate: event.customTaxRate,
        taxCalculationMethod: event.taxCalculationMethod,
        paymentPlanSettings: event.paymentPlanSettings,
      };
    }
  })();

  function normaliseTicket(
    rztt: EventAndReleaseZoneTicket,
    zoneName?: string,
    zoneId?: string
  ): IReleaseTicket {
    const tt = event.ticketTypes.find((t) => t.id === rztt.ticketTypeId);

    if (!tt) return;

    return {
      type: "ticket",
      id: tt.id,
      zoneId: zoneId,
      zoneName: zoneName,
      kind: tt.kind,
      name: tt.name,
      description: tt.description,
      imageGallery: tt.imageGallery,
      restrictions: tt.restrictions,
      image: tt.ticketImage,
      price: rztt.price,
      isSoldOut: rztt.isSoldOut,
      isActive: rztt.isActive,
      inHighDemand: tt.inHighDemand,
      purchaseRestrictions: rztt.purchaseRestrictions,
      purchaseRestrictionsHelpText: rztt.purchaseRestrictionsHelpText,
      availabilityStatus: rztt.ticketAvailabilityStatus,
      minPurchaseQuantity: rztt.minPurchaseQuantity,
      maxPurchaseQuantity: calculateMaxPurchaseQuantity(
        rztt.maxPurchaseQuantity,
        normalisedData?.digitalPassCodesStatus?.remainingPassCodes
      ),
      ticketFee: tt.bookingFee,
      ticketFeeType: tt.bookingFeeType,
      stadiumLevyFee: tt.stadiumLevyFee,
      quantity: undefined,
    };
  }

  const zones: IReleaseZone[] = release?.releaseZones
    .map((rz) => ({
      id: rz.zone.id,
      name: rz.zone.name,
      isActive: rz.isActive,
      pricingLevel: rz.pricingLevel,
      displayOrder: rz.zone.displayOrder,
      description: rz.zone.description?.trim(),
      imageGallery: rz.zone.imageGallery,
      ticketTypes: rz.ticketTypes
        .map((tt) => normaliseTicket(tt, rz.zone.name, rz.zone.id))
        .filter((tt) => tt),
    }))
    .sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1));

  const addons: IReleaseTicket[] = release?.releaseEventAddons
    .map((addon) => {
      const ao = addon.eventAddon;

      const addonExists = event.addons.some((a) => a.id === ao.id);

      if (!addonExists) return;

      return {
        type: "addon" as TicketTypeType,
        id: ao.id,
        name: ao.name,
        description: ao.description,
        imageGallery: ao.imageGallery,
        restrictions: ao.restrictions,
        image: ao.addonImage,
        price: ao.price,
        isActive: ao.isActive,
        isSoldOut: false,
        inHighDemand: false,
        purchaseRestrictions: ao.purchaseRestrictions,
        purchaseRestrictionsHelpText: ao.purchaseRestrictionsHelpText,
        availabilityStatus: ao.addonAvailabilityStatus,
        ticketFee: null,
        ticketFeeType: null,
        stadiumLevyFee: null,
        quantity: ao.quantity,
      };
    })
    .filter((ao) => ao);

  return {
    data: normalisedData,
    zones,
    addons,
    release,
  };
}

export function calculateMaxPurchaseQuantity(
  maxPurchaseQuantity: number,
  remainingPassCodes: number
) {
  const defaultMaxPurchaseQuantity = 40;
  if (!maxPurchaseQuantity && !remainingPassCodes) {
    return undefined;
  }

  if (!maxPurchaseQuantity && remainingPassCodes) {
    return Math.min(remainingPassCodes, defaultMaxPurchaseQuantity);
  }

  if (maxPurchaseQuantity && !remainingPassCodes) {
    return maxPurchaseQuantity;
  }

  return Math.min(remainingPassCodes, maxPurchaseQuantity);
}

const buildSharedDataModel = useCreateSharedDataModel;

export { buildSharedDataModel };
