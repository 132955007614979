import {
  Flex,
  Container,
  Spinner,
  Text,
  Box,
  Stack,
  TertiaryButton,
} from "flicket-ui";
import {
  Footer,
  BottomBar,
  Seo,
  ContentContainer,
  TransitionAnimation,
} from "~components";
import { EventsList } from "~features/events/hooks/useEventsService";
import { EventQuery } from "~graphql/sdk";
import { AnimatePresence } from "framer-motion";
import {
  INonSeatedEventReservation,
  useViewModel,
} from "~features/nonseated-reservation/components/NonSeatedEventReservation";
import { useOrganization } from "~hooks";
import { NonSeatedReservationPage } from "~features/reservation/non-seated/NonSeatedReservationPage";
import { Skeleton } from "~components/common/LoadingSkeleton";
import { EventsCalendar } from "~components/calendar/calendar";
import { addQueryParams } from "~lib/helpers/queryParams";
import { useRouter } from "next/router";

interface CalendarProps {
  isAdmin: boolean;
  isLoading: boolean;
  release: INonSeatedEventReservation["release"];
  selectedEvent: INonSeatedEventReservation["event"];
  selectedEventId: string;
}

const EventsCalendarOverview = ({
  release,
  isAdmin,
  selectedEvent,
  selectedEventId,
  isLoading,
}: CalendarProps) => {
  const router = useRouter();

  function handleSelectEvent(eventId: string) {
    void router.push(
      {
        pathname: `/events/[eventId]/reservation`,
        query: {
          ...router.query,
          eventId: eventId,
        },
      },
      undefined
      // { shallow: true }
    );
  }

  return (
    <>
      <Seo title="Calendar" description="Calendar" />
      <ContentContainer variant="content">
        <Flex flexDir="column" flex="1" mb={6}>
          <Container innerProps={{ minHeight: { _: 0, md: "320px" } }}>
            <EventsCalendar handleSelectEvent={handleSelectEvent} />

            <AnimatePresence exitBeforeEnter>
              {selectedEventId && (
                <TransitionAnimation>
                  {isLoading ? (
                    // <LoadingSkeleton />
                    <Flex
                      variant="center"
                      flex="1"
                      p={4}
                      flexDirection="column"
                    >
                      <Spinner size={48} color="P300" data-testid="spinner" />
                    </Flex>
                  ) : (
                    <EventReservationDetail
                      event={selectedEvent}
                      release={release}
                      isAdmin={isAdmin}
                    />
                  )}
                </TransitionAnimation>
              )}
            </AnimatePresence>
          </Container>
        </Flex>
        <Footer />
      </ContentContainer>
    </>
  );
};

export default EventsCalendarOverview;

function EventReservationDetail(props: INonSeatedEventReservation) {
  const VM = useViewModel(props);
  return (
    <NonSeatedReservationPage
      isMembership={VM.isMembership}
      zones={VM.zones}
      addons={VM.addons}
      data={VM.data}
      createOrder={VM.handleOrderCreation}
      releaseNotes={VM.release?.releaseNotes}
      allowPublicComp={VM.release?.allowPublicComp}
      isResaleRelease={VM.release?.isResaleRelease}
      releaseType={VM.release?.type}
      requiresAuth={VM.release?.requiresAuth}
      isAdmin={VM.isAdmin}
      calendarView={true}
      releaseId={VM.release?.id}
      membershipId={VM.isMembership ? VM.data?.id : undefined}
    />
  );
}

function LoadingSkeleton() {
  return (
    <Box width={1}>
      <Box
        width={1}
        px={{ _: 0, sm: 2 }}
        margin={"auto" as any}
        mb={4}
        id="event-banner"
      >
        <Skeleton />
      </Box>

      <Box width={1} px={2} margin={"auto" as any} mb={2}>
        <Stack gap={3} flexWrap="wrap">
          <Box flexGrow={1}>
            <Text variant="header.L" mb="1/2">
              <Skeleton />
            </Text>
            <Text variant="regular">
              {" "}
              <Skeleton />
            </Text>
          </Box>
          <TertiaryButton
            alignSelf="flex-start"
            px={2}
            py={1}
            borderColor="N300"
            borderRadius="xs"
          >
            <Text variant="header.XS">Event details</Text>
          </TertiaryButton>
        </Stack>
      </Box>

      <Box width={1} px={2} margin={"auto" as any}>
        {/* <ReservationList */}
      </Box>
    </Box>
  );
}
